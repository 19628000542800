import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PageBannerSVG } from "../../Assets/img/lkwb.svg";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bannerInfo, servicesInfo } from "../../Features/HomeApi/HomeSlice";
const HeroSection = () => {
  const dispatch = useDispatch();
  let services_Info = useSelector((state) => state.home.servicesInfo);
  let bannerimg = useSelector((state) => state.home.siteInfo.web_banner_image);
  let banner_Info = useSelector((state) => state.home.bannerInfo);
  useEffect(() => {
    dispatch(bannerInfo());
    dispatch(servicesInfo());
  }, [dispatch]);
  const navigateTo = (title) => {
    let url;
    switch (title) {
      case "LKWB UBT Test":
        url = "/ubt-test";
        break;
      case "LKWB Grammar":
        url = "/grammar";
        break;
      case "LKWB Dictionary":
        url = "/dictionary";
        break;
      case "LKWB Discussion":
        url = "/discussion";
        break;
      case "LKWB Blog":
        url = "/blog";
        break;
      case "Books":
        url = "/books";
        break;
      default:
        url = "/services";
        break;
    }
    return url;
  };
  return (
    <>
      {banner_Info && (
        <section className="banner__section">
          <div className="banner--img">
            <div className="mainimg">
              <img src={bannerimg} alt={banner_Info.top_text} />
            </div>
            <div className="banner--text">
              <Container>
                <span>Experience new way of Learning</span>
                <h1>Make your 'Korea dream' come true.</h1>
                <p>{banner_Info.description}</p>
              </Container>
            </div>
          </div>

          <div className="bannerServices ">
            <Container>
              <Row>
                {services_Info &&
                  services_Info.services &&
                  services_Info.services.slice(0, services_Info.services.length - 1).map((item, id) => (
                    <Col md={3} key={id} sm={3}>
                      <Link to={navigateTo(item.title)} className="flip-card">
                        <div className="eachservicecard flip-card-inner">
                          <div className="filp-cardFace flip-card-front">
                            <div className="eachservice-img">
                              <img src={item.icon} alt="UBT Test" />
                            </div>
                            <h3>{item.title}</h3>
                            <PageBannerSVG />
                          </div>
                          <div className="filp-cardFace flip-card-back">
                            <h3>{item.title}</h3>
                            <p>{item.excerpt}</p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ))}
                {/* <Col md={3} sm={3}>
                  <Link to="/grammar">
                    <div className="eachservicecard">
                      <div className="eachservice-img">
                        <img src={DictionaryImg} alt="UBT Test" />
                      </div>
                      <h3>LKWB Grammar</h3>
                      <PageBannerSVG />
                    </div>
                  </Link>
                </Col>
                <Col md={3} sm={3}>
                  <Link to="/dictionary">
                    <div className="eachservicecard">
                      <div className="eachservice-img">
                        <img src={GrammarImg} alt="Grammar" />
                      </div>
                      <h3>LKWB Dictionary</h3>
                      <PageBannerSVG />
                    </div>
                  </Link>
                </Col>
                <Col md={3} sm={3}>
                  <Link to="/discussion">
                    <div className="eachservicecard">
                      <div className="eachservice-img">
                        <img src={DiscussionImg} alt="UBT Test" />
                      </div>
                      <h3>LKWB Discussion</h3>
                      <PageBannerSVG />
                    </div>
                  </Link>
                </Col> */}
              </Row>
            </Container>
          </div>
        </section>
      )}
    </>
  );
};

export default HeroSection;
